<template>
  <v-card>
    <v-card-title class="headline">
      Deseja recusar essa DPS?
    </v-card-title>

    <v-card-text>
      Ao definir essa DPS como recusada, um email será enviado ao Closer informando que a empresa
      não terá seu benefício aprovado
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn text @click="cancel()">
        Cancelar
      </v-btn>

      <v-btn text @click="save()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
/* tslint:disable */
/* eslint-disable */

export default {
  name: "RefusedStep",
  props: ["toggle", "callback", "onCancel"],
  methods: {
    save() {
      if (this.callback) {
        this.callback();
      }

      this.close();
    },
    close() {
      if (this.toggle) {
        this.toggle();
      }
    },
    cancel() {
      if (this.onCancel) this.onCancel();
      this.close();
    }
  }
};
</script>
